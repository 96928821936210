import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { FormattedMessage as Translation } from 'react-intl';
import { FullPageLoader } from 'panamera-react-ui';
import AsyncCategoryOptions from './AsyncCategoryOptions.mobile';
import AsyncSubCategoryOptions from './SubCategoryBrowsing/AsyncSubCategoryOptions.mobile';
import { getNearByAds } from 'Actions/categoryBrowsing';
import { navigationTreeSelector, navigationTreeByIdSelector, updatedNavigationTreeSelector } from 'Selectors/navigationTree';
import { buildURL, getUrlWithhash } from 'Helpers/url';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import {
    BROWSE_CATEGORY_SELECT,
    TAP_ALLCATEGORIES,
    BROWSE_SUBCATEGORY_SELECT,
    EXTERNAL_CAT_TRACK
} from 'Constants/tracking';
import css from './CategoryBrowsing.mobile.scss';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { XX } from 'Constants/images';
import { OTO_PLUS_ICON } from 'Constants/letgo';
import { configSelector } from 'Selectors/config';
import { OLX_RENEW_MOBILE_NAME } from 'Constants/constants';
import CategorySection from './CategorySection';
import { getSessionFeatures } from 'Helpers/config';

export class CategoryBrowsing extends React.Component {
    static propTypes = {
        categories: PropTypes.array,
        getCategoryById: PropTypes.func.isRequired,
        getNearByAds: PropTypes.func.isRequired,
        onClickFloatingPill: PropTypes.func,
        track: PropTypes.func.isRequired,
        config: PropTypes.object,
        router: PropTypes.object.isRequired,
        location: PropTypes.object,
        addCategoryImpression: PropTypes.func,
        otoPlusCategory: PropTypes.array
    };

    static defaultProps = {
        showLoader: false,
        onClickFloatingPill: () => {},
        addCategoryImpression: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            categorySelected: null,
            isLoading: false
        };

        this.flag = (getSessionFeatures() || [])?.includes('olxin-7129');
    }

    componentDidMount() {
        const propsLocationHash = this.props.location.hash;

        if (this.getCategoryId(propsLocationHash) && this.hasCategoryHash(propsLocationHash)) {
            this.openModal(this.getCategoryId(propsLocationHash));
        }

        const findValidTrackingKey = (this.props.categories || []).find(category => category.trackingKey)?.trackingKey || null;

        if (findValidTrackingKey) {
            this.props.track(EXTERNAL_CAT_TRACK.external_icon_shown, { select_from: findValidTrackingKey });
        }
    }

    componentDidUpdate(prevProps) {
        const prevPropsLocationHash = prevProps.location && prevProps.location.hash;
        const propsLocationHash = this.props.location.hash;

        if (prevPropsLocationHash !== propsLocationHash) {
            if (prevPropsLocationHash && !this.flag) {
                this.closeModal();
            }
            else if (propsLocationHash && this.hasCategoryHash(propsLocationHash)) {
                this.openModal(this.getCategoryId(propsLocationHash));
            }
        }
    }

    hasCategoryHash(hash) {
        return !!hash.match(/^#[0-9]*$/g);
    }

    getCategoryId = locationHash => {
        return locationHash.slice(1);
    }

    openModal = categoryId => {
        this.setState({
            isOpened: true,
            categorySelected: categoryId
        });
    }

    closeModal = () => this.setState({ isOpened: false });

    getLinkProps = ({ id, name, sub_categories = [], redirection_url, enabled }) => {
        if (sub_categories.length === 1) {
            const subCategory = {
                id: sub_categories[0].id,
                name: sub_categories[0].name
            };
            const url = buildURL({ category: subCategory });

            return {
                to: url,
                handleClick: ev => {
                    ev.stopPropagation();
                    this.handleShowOptions(id, false);
                    this.onSelectSubCategory(id, subCategory.id);
                }
            };
        }

        if (id === OLX_RENEW_MOBILE_NAME && enabled) {
            return {
                to: redirection_url,
                handleClick: () => {}
            };
        }

        if (id === OTO_PLUS_ICON) {
            return {
                to: this.props.otoPlusCategory?.[0]?.redirection_url,
                handleClick: () => {},
                target: '_blank'
            };
        }

        return {
            to: buildURL({ category: { id, name }}),
            handleClick: this.handleCategoryLink
        };
    }

    handleCategoryLink = event => {
        event.preventDefault();
    }

    /**
     * @name handleShowOptions
     * @param {id: string|number} - id of the given category
     * @desc sets the 'isOpened' flag true for the given category id
     */
    handleShowOptions = (id = null, reroute = true, category_id, name) => {
        const { location, router } = this.props;
        let updatedUrl = '';
        let eventName;
        let trackingInfo = {};
        let isOpened = true;

        if (id) {
            if (id === OTO_PLUS_ICON || id === OLX_RENEW_MOBILE_NAME) {
                updatedUrl = '';
                isOpened = false;
            }
            else {
                updatedUrl = getUrlWithhash({ location, hashValue: id });
            }
            eventName = BROWSE_CATEGORY_SELECT;
            trackingInfo = {
                select_from: 'carousel',
                category_level1_id: id === OLX_RENEW_MOBILE_NAME ? category_id : id,
                category_level1_name: name
            };
        }
        else {
            updatedUrl = getUrlWithhash({ location, hashValue: 'seeAll' });
            eventName = TAP_ALLCATEGORIES;
        }

        this.props.track(eventName, trackingInfo);
        if (reroute) {
            const navigationMethod = this.state.isOpened && this.flag ? 'replace' : 'push';

            router[navigationMethod](updatedUrl);
        }

        this.setState({
            isOpened,
            categorySelected: id
        });
    };

    onSelectSubCategory = (categoryId = null, subCategoryId = null) => {
        this.props.track(BROWSE_SUBCATEGORY_SELECT, {
            category_level1_id: categoryId,
            category_level2_id: subCategoryId
        });
    }

    handleNearByAds = () => {
        this.props.track('browse_ads_nearby');
        this.props.getNearByAds(this.handleLoading)
            .then(location => this.props.router.push(buildURL({ location })))
            .catch(() => this.props.router.push('/items'));
    };

    handleHideOptions = () => {
        this.props.router.goBack();
        this.setState({ isOpened: false });
    }

    handleLoading = isLoading => this.setState({ isLoading });

    renderCategoryIcon = id => {
        const siteCode = this.props.config.get('siteCode');

        return (<ImageWrapper
            fileName={ `/${siteCode}/category_icons/v4/category_${id}_$width$.$ext$` }
            className={ css.categoryIcon }
            fileSize={ XX }
        />);
    }

    /**
     * @name getCategories
     * @params none
     * @returns the jsx list for the category icons with links to sub-categories
     */
    getCategories() {
        const { categories, addCategoryImpression } = this.props;

        return <CategorySection categories={ categories } handleClick={ this.handleShowOptions } getLinkProps={ this.getLinkProps } addCategoryImpression={ addCategoryImpression } />;
    }

    handleSeeAllClick = () => this.handleShowOptions();

    renderLoading() {
        if (this.state.isLoading) {
            const text = <Translation id="loadingAdsToRelocate" />;

            return (
                <FullPageLoader
                    className={ css.loader }
                    text={ text }
                />
            );
        }
        return null;
    }

    render() {
        const SubCategoryBrowsingComponent = this.flag ? AsyncSubCategoryOptions : AsyncCategoryOptions;

        return (
            <React.Fragment>
                {this.getCategories()}
                {this.state.isOpened && (

                    <SubCategoryBrowsingComponent
                        categories={ this.props.categories }
                        onClick={ this.handleShowOptions }
                        onClose={ this.handleHideOptions }
                        defaultOption={ this.state.categorySelected }
                        getCategoryById={ this.props.getCategoryById }
                        onClickFloatingPill={ this.props.onClickFloatingPill }
                        onSelectSubCategory={ this.onSelectSubCategory }
                    />
                )}
                {this.renderLoading()}
            </React.Fragment>
        );
    }
}

export const mapStateToProps = state => {
    const otoPlusCategory = configSelector(state)?.filtered_categories;
    const olxRenewCategoryFeature = configSelector(state)?.features;
    const categories = navigationTreeSelector(state, otoPlusCategory);

    return {
        categories: updatedNavigationTreeSelector(categories, olxRenewCategoryFeature, state),
        getCategoryById: navigationTreeByIdSelector(state),
        otoPlusCategory
    };
};

export const mapDispatchToProps = dispatch => ({
    getNearByAds: callback => dispatch(getNearByAds(callback, false))
});

export default compose(
    withConfig,
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(CategoryBrowsing);
