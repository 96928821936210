/* global window */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { Link } from 'panamera-react-ui';
import { FormattedMessage } from 'react-intl';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import css from './CategorySection.scss';
import { compose } from 'redux';
import withConfig from 'HOCs/withConfig/withConfig';
import { XX } from 'Constants/images';
import PropTypes from 'prop-types';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import withTrack from 'HOCs/withTrack/withTrack';
import { EXTERNAL_CAT_TRACK } from 'Constants/tracking';

const CATEGORY_ICON_PATH = '/category_icons/v4/category';

export const CategorySection = ({ categories = [], config, handleClick, getLinkProps, addCategoryImpression, track }) => {
    const renderCategoryIcon = (id, name) => {
        const siteCode = config.get('siteCode');

        return (
            <ImageWrapper
                fileName={ `/${siteCode}${CATEGORY_ICON_PATH}_${id}_$width$.$ext$` }
                className={ css.categoryIcon }
                fileSize={ XX }
                alt={ `category_${name}` }
            />);
    };

    const handleSeAllClick = useCallback(() => {
        handleClick();
    }, [handleClick]);

    const isEven = (categories
        || []).length % 2 === 0;

    const getCards = () => {
        const oddRow = [];
        const evenRow = [];

        const categoryLength = categories?.length ?? 0;

        const onCategoryView = (isVisible, name) => {
            if (isVisible) {
                addCategoryImpression(name);
            }
        };

        for (let i = 0; i < categoryLength; i += 2) {
            const oddItem = categories[i];
            const oddLinkProps = getLinkProps(oddItem);

            const allowedRedirectionTypes = ['deeplink', 'webView', 'in_app_browser'];
            const isRedirectionType = allowedRedirectionTypes.includes(oddItem.redirectionType);
            const oddOnClick = () => (!isRedirectionType && handleClick(oddItem.id, true, oddItem.category_id, oddItem.name));
            const handleItemClick = e => {
                if (isRedirectionType) {
                    e.preventDefault();
                    track(EXTERNAL_CAT_TRACK.externa_icon_clicked, { select_from: oddItem.trackingKey });
                    window.location.href = oddItem.redirectionUrl;
                }
                else {
                    oddLinkProps.handleClick(e);
                }
            };

            const oddRowItem = (
                <ReactObserver data-aut-id={ 'observer-odd' } onChange={ v => onCategoryView(v, oddItem.name) } className={ css.categoryItemContainer } key={ `category-browsing-${oddItem.id}` } onClick={ oddOnClick }>
                    <Link
                        to={ oddLinkProps.to }
                        onClick={ handleItemClick }
                        key={ `category-browsing-${oddItem.id}` }
                        className={ css.link }
                    >
                        <div className={ css.categoryItem }>
                            {renderCategoryIcon(oddItem.id, oddItem.name)}
                            {oddItem.bottomTag && (
                                <div
                                    className={ css.bottomTag }
                                    style={ {
                                        backgroundColor: oddItem.bottomTag?.bgColor,
                                        color: oddItem.bottomTag?.textColor
                                    } }
                                >
                                    {oddItem.bottomTag?.name}
                                </div>
                            )}
                        </div>
                        <div className={ css.categoryName }>{oddItem.name}</div>
                    </Link>
                </ReactObserver>
            );

            const handleEvenItemsRow = i + 1 < categoryLength;

            // Handle even item if it exists
            const evenRowitem = handleEvenItemsRow ? (
                (() => {
                    const evenItem = categories[i + 1];
                    const evenLinkProps = getLinkProps(evenItem);
                    const isRedirectionType = allowedRedirectionTypes.includes(evenItem.redirectionType);
                    const evenOnClick = () => (!isRedirectionType && handleClick(evenItem.id, true, evenItem.category_id, evenItem.name));

                    const handleItemClick = e => {
                        if (isRedirectionType) {
                            e.preventDefault();
                            track(EXTERNAL_CAT_TRACK.externa_icon_clicked, { select_from: evenItem.trackingKey });
                            window.location.href = evenItem.redirectionUrl;
                        }
                        else {
                            evenLinkProps.handleClick(e);
                        }
                    };

                    return (
                        <ReactObserver data-aut-id={ 'observer-even' } onChange={ v => onCategoryView(v, evenItem.name) } className={ css.categoryItemContainer } key={ `category-browsing-${evenItem.id}` } onClick={ evenOnClick }>
                            <Link
                                to={ evenLinkProps.to }
                                onClick={ handleItemClick }
                                key={ `category-browsing-${evenItem.id}` }
                                className={ css.link }
                            >
                                <div className={ css.categoryItem }>
                                    {renderCategoryIcon(evenItem.id, evenItem.name)}
                                    {evenItem.bottomTag && (
                                        <div
                                            className={ css.bottomTag }
                                            style={ {
                                                backgroundColor: evenItem.bottomTag?.bgColor,
                                                color: evenItem.bottomTag?.textColor
                                            } }
                                        >
                                            {evenItem.bottomTag?.name}
                                        </div>
                                    )}
                                </div>
                                <div className={ css.categoryName }>{evenItem.name}</div>
                            </Link>
                        </ReactObserver>
                    );
                })()
            ) : (
                <div className={ `${css.seeAll} ${css.odd}` } onClick={ handleSeAllClick }> <IconWrapper className={ css.nextIcon } icon="back" size={ 12 } />  <FormattedMessage id="seeAll" /></div>
            );

            oddRow.push(oddRowItem);
            evenRow.push(evenRowitem);
        }

        return (
            <div className={ css.categorySection }>
                <div className={ css.categoryRow }>
                    {oddRow}
                </div>
                <div className={ css.categoryRow }>
                    {evenRow}
                </div>
            </div>
        );
    };

    return (
        <div className={ css.categorySectionContainer } data-aut-id={ 'categorySection' }>
            {getCards()}
            {
                isEven && <div className={ `${css.seeAll} ${css.even}` } onClick={ handleSeAllClick }> <IconWrapper className={ css.nextIcon } icon="back" size={ 12 } color="blackIcons" />  <FormattedMessage id="seeAll" /> </div>
            }
        </div>
    );
};

CategorySection.propTypes = {
    categories: PropTypes.array,
    config: PropTypes.object,
    handleClick: PropTypes.func,
    getLinkProps: PropTypes.func,
    addCategoryImpression: PropTypes.func,
    track: PropTypes.func.isRequired
};

export default compose(
    withConfig,
    withTrack
)(CategorySection);
